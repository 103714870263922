// Import the functions you need from the SDKs you need
const firebase = require("firebase/app");
const { initializeApp, getAnalytics } = require("firebase/app");
const { getFunctions } = require("firebase/functions");
const {getAuth, onAuthStateChanged} = require("firebase/auth");
const { useState, useEffect } = require("react");

const { getFirestore} = require("firebase/firestore");
const { collection, query, orderBy, limit, getDocs, getDoc, doc, setDoc} = require("firebase/firestore");

const { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, updateProfile, getIdToken, signInWithEmailAndPassword} = require("firebase/auth");




// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB9uUWqfrU-K9VZMviB7xAlD8N-CAE7gtQ",
  authDomain: "trading-bot-cd9b9.firebaseapp.com",
  databaseURL: "https://trading-bot-cd9b9-default-rtdb.firebaseio.com",
  projectId: "trading-bot-cd9b9",
  storageBucket: "trading-bot-cd9b9.appspot.com",
  messagingSenderId: "1003195668921",
  appId: "1:1003195668921:web:db5e1ab049f655cb2ebe68",
  measurementId: "G-ECD4431KJZ"
};

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

const auth = getAuth(app);


// Define the function that will be triggered by onCall
// const processAnalysisResults = functions.https.onCall((data, context) => {
//   // Your logic for processing analysis results goes here
//   return {
//     data: "Processed successfully"
//   };
// });

// Export the function
// module.exports = {
//   processAnalysisResults
// };
const db = getFirestore(app);

const fetchLatestAnalysisResult = async () => { 
  try { 
    // Get a reference to the 'analysisResult' collection
    const collectionName = 'analysisResult';
    const analysisResultRef = collection(db, collectionName);
    console.log('analysisResultRef:', analysisResultRef);

    // Create a query to fetch the latest analysis result
    const q = query(analysisResultRef, orderBy('date', 'desc'), limit(1));

    // Execute the query and get the result
    const querySnapshot = await getDocs(q);

    // Check if there are any documents in the result
    if (!querySnapshot.empty) {
      // Extract the latest document data
      const latestDocumentData = querySnapshot.docs[0].data();
      return latestDocumentData;
    } else {
      console.log('No analysis result found.');
      return null;
    }
  } catch (error) {
    console.error('Error fetching analysis result:', error);
    return null;
  } 
};


const fetchNewsArticles = async () => {
  try {
    // Get a reference to the 'news' collection
    const collectionName = 'newsSentimentArticles';
    const newsRef = collection(db, collectionName);
    console.log('newsRef:', newsRef);

    // Create a query to fetch the latest news articles
    const q = query(newsRef, orderBy('date', 'desc'), limit(5));

    // Execute the query and get the result
    const querySnapshot = await getDocs(q);

    // Check if there are any documents in the result
    if (!querySnapshot.empty) {
      // Extract the latest news articles data
      const newsArticles = querySnapshot.docs.map(doc => doc.data());
      return newsArticles;
    } else {
      console.log('No news articles found.');
      return [];
    }
  } catch (error) {
    console.error('Error fetching news articles:', error);
    return [];
  }
}

const getUser = () => {
  return new Promise((resolve, reject) => {
    const authInstance = getAuth();
    onAuthStateChanged(authInstance, (user) => {
      if (user) {
        resolve(user);
      } else {
        reject(new Error("User not signed in"));
      }
    });
  });
};
 // Register the user with Firebase Authentication


const RegisterAccount = async (email, password) => {
  try {
    const authInstance = getAuth();
    const userCredential = await createUserWithEmailAndPassword(
      authInstance,
      email,
      password,
    );
    const user = userCredential.user;

    // Set the user's display name (optional)
    await updateProfile(user, { displayName: email });

    // Set the custom claim to indicate paid status
    await getIdToken(user, true); // Force refresh to get updated custom claims
    // Store additional user data in Firestore
    await setDoc(doc(db, "users", user.uid), {
      paid: true,
      paidTimestamp: new Date().getTime(), // Store the timestamp when the user became paid
      trial: true
    });
    return user;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const registerWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const authInstance = getAuth(); // Get the authentication instance

    const result = await signInWithPopup(authInstance, provider);

    // After signing in with Google, you can proceed to update user details
    const user = result.user;

    // Set the custom claim to indicate paid status
    await getIdToken(user, true); // Force refresh to get updated custom claims
    // Store additional user data in Firestore (optional)
    await setDoc(doc(db, "users", user.uid), {
      paid: true,
      paidTimestamp: new Date().getTime(), // Store the timestamp when the user became paid
      trial: true
    });

    // Log the user information
    console.log(user);
  } catch (error) {
    console.error(error);
    // Handle errors
  }
}

const checkAndUpdatePaidStatus = async (uid) => {
  try {
    // Retrieve user document from Firestore
    const userDoc = await getDoc(doc(db, "users", uid));
    const userData = userDoc.data();

    if (
      userData &&
      userData.paid &&
      userData.trial &&
      new Date().getTime() - userData.paidTimestamp > 24 * 60 * 60 * 1000
    ) {
      // More than 24 hours have passed, update paid status in Firestore
      await setDoc(doc(db, "users", uid), {
        paid: false, // Set paid status to false
        trial: false, // Set trial status to false
      });
    }
  } catch (error) {
    console.error("Error checking and updating paid status:", error);
  }
};

const getUserData = async (uid) => {
  try {
    // Retrieve user document from Firestore
    const userDoc = await getDoc(doc(db, "users", uid));
    return userDoc.data();
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
}
const log = async (loginEmail, loginPassword) => {
  try {
    const authInstance = getAuth();
    const userCredential = await signInWithEmailAndPassword(
      authInstance,
      loginEmail,
      loginPassword,
    );
    const user = userCredential.user;

    // Call the function to check and update paid status
    checkAndUpdatePaidStatus(user.uid);
    console.log("User logged in successfully. User:", user);
    return "true";  

    // Handle successful login, e.g., redirect to another page
  } catch (error) {

    console.error(error);
    return error.code;
    // Handle login error, e.g., show an error message
  }
};

const googleLog = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const auth = getAuth(app);
    const authInstance = auth;
    const result = await signInWithPopup(authInstance, provider);
    const user = result.user;

    console.log("User logged in successfully. User:", user)
    // Call the function to check and update paid status
    checkAndUpdatePaidStatus(user.uid);

    return true;
    // Handle successful login, e.g., redirect to another page
  } catch (error) {
    console.error(error);
    // Handle login error, e.g., show an error message
    return false;
  }
};

const logout = async () => {
  try {
    const authInstance = getAuth();
    await authInstance.signOut();
    // Handle successful logout, e.g., redirect to another page
  } catch (error) {
    console.error(error);
    // Handle logout error, e.g., show an error message
  }
};


const cancelSubscription = async (uid) => {
  try {
    // Update the user document in Firestore to cancel the subscription
    await setDoc(doc(db, "users", uid), {
      paid: false,
      trial: false,
    });
  } catch (error) {
    console.error("Error canceling subscription:", error);
  }
}




export { logout,fetchLatestAnalysisResult, getAuth, fetchNewsArticles, getUser, db, auth, firebaseConfig, registerWithGoogle, RegisterAccount, log, googleLog, getUserData, cancelSubscription};